export const isAuthenticatedUserMemberOfAdmin = (user) => {
  console.log("user", user)
  const {
    signInUserSession: {
      idToken: { payload },
    },
  } = user
  console.log(payload["cognito:groups"])
  return (
    payload["cognito:groups"] &&
    payload["cognito:groups"].includes("Admin")
  )
}
