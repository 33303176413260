import React from "react"
import { appendToCognitoUserAgent } from "@aws-amplify/auth"
import { AmplifyAuthenticator } from "@aws-amplify/ui-react"
import {
  onAuthUIStateChange,
  AuthState,
} from "@aws-amplify/ui-components"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { isAuthenticatedUserMemberOfAdmin } from "../utils/auth"
import { Container } from "reactstrap"

const Login = () => {
  React.useEffect(() => {
    appendToCognitoUserAgent("withAuthenticator")
    return checkUser()
  }, [])

  function checkUser() {
    return onAuthUIStateChange(
      (nextAuthState, authData) => {
        if (nextAuthState === AuthState.SignedIn) {
          const isAdmin =
            isAuthenticatedUserMemberOfAdmin(authData)
          if (isAdmin) {
            navigate("/admin/runs/edit")
          } else {
            navigate("/")
          }
        }
      }
    )
  }

  return (
    <>
      <PageTitle title="Login" />
      <SEO />
      <Container className="pt-4">
        <AmplifyAuthenticator />
      </Container>
    </>
  )
}

export default Login
